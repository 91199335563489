import React from "react";
import image from "./images/pexels-tranmautritam-326503.jpg";

export default function Home() {
  return (
    <main>
      <section className="landing">
        <div className="left">
          <h1>Your Digital Presence Starts Now.</h1>
          <p>
            Specializing in crafting custom, beautiful websites that are
            designed to help small businesses grow.
          </p>
        </div>

        <div className="right">
          <img src={image}></img>

          <a href="/contact" className="hoverbtn">
            Get In Touch
          </a>
        </div>
      </section>
    </main>
  );
}
